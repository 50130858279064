import axios, { AxiosError } from "axios";
import { cloneDeep, findIndex, isArray, isEmpty } from "lodash";
import toastr from "src/components/Common/toastr";
import {
  getHLSViewerRoleLayout,
  getHLSViewerRolesFromRoleLayouts,
  updateAppLayout,
} from "src/helpers/appLayoutHelper";
import {
  fetchAppLayout,
  PATCH_APP_LAYOUT_DONE,
  PATCH_APP_LAYOUT_FAIL,
  PATCH_APP_LAYOUT_INIT,
  updateAppLayoutInStore,
} from "src/store/appLayout/actions";
import { RootState } from "src/store/reducers";
import { DashboardBackendAxiosError } from "src/types/customTypes";
import {
  hlsDestinationsLayerType,
  hlsDestinationsType,
  policyTemplateType,
  policyType,
  RecordingConfigType,
  roleTemplatePolicy,
  roleType,
  rtmpDestinationsType,
  templatePluginTypes,
  TranscriptionType,
  whiteboardPermissionsType,
} from "src/types/policyTypes";
import { RoleLayouts } from "src/types/prebuilt";
import api from "../api";
import { API_CALL_STATE, defaultRecordingObject } from "../constants";
import { AppAnalytics } from "../helpers/analytics_helper";
import {
  getVisibleRoles,
  HLS_VIEWER_ROLE,
  INTERNAL_RECORDER_ROLE,
  isVisibleRole,
  NEW_HLS_VIEWER_ROLE,
} from "../helpers/roles_helper";
import { AppDispatch } from "../store";
import { roleTemplateForPolicy } from "../store/policyTemplate";
import { fetchRecentRooms } from "../store/rooms/actions";
import { getAPIURL } from "../utils";
import {
  validateHLSdestinationFields,
  validateRecordings,
  validateRecordingsConfigFields,
  validateRTMPdestinationFields,
  validateTranscriptionFields,
} from "../validations/destinations";
import { validatePlugins as validatePluginsInfo } from "../validations/plugins";
export const fetchPolicy = (policy_id: string) => {
  return api
    .service("dashboard")
    .get(getAPIURL("get-policy-info"), { policy_id });
};

export const updatePolicy = ({
  policy_id,
  data,
}: {
  policy_id: string;
  data: policyTemplateType;
}) => {
  return api.service("dashboard").post("update-app-v2", { policy_id, data });
};

export function updatePolicyAndAppLayout({
  policy,
  roleLayouts,
  analytics,
}: {
  policy: policyType;
  roleLayouts: RoleLayouts;
  analytics?: {
    event?: string | null;
    successAnalyticsData?: {};
    failAnalyticsData?: {};
  };
}) {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const { updatePolicyStatus } = getState().roles;
    const { patchAppLayoutStatus } = getState().appLayouts;
    if (
      updatePolicyStatus === API_CALL_STATE.IN_PROGRESS ||
      patchAppLayoutStatus === API_CALL_STATE.IN_PROGRESS
    ) {
      return;
    }
    dispatch({ type: UPDATE_POLICY_INFO_INIT });
    dispatch({ type: PATCH_APP_LAYOUT_INIT });
    try {
      let updatedPolicy: policyTemplateType;
      let updatedRoleLayouts: RoleLayouts;
      await updatePolicy({
        policy_id: policy.id,
        data: policy,
      })
        .then(resp => {
          if (resp.data.success) {
            updatedPolicy = policy;

            updateAppLayout({
              roleLayouts: roleLayouts,
            })
              .then(res => {
                if (res.data.success) {
                  if (analytics?.event) {
                    AppAnalytics.track(
                      `${analytics.event}.success`,
                      analytics.successAnalyticsData
                    );
                  }
                  updatedRoleLayouts = res.data.data;
                  dispatch({
                    type: UPDATE_POLICY_INFO_DONE,
                    payload: updatedPolicy,
                  });
                  dispatch({
                    type: PATCH_APP_LAYOUT_DONE,
                    payload: updatedRoleLayouts,
                  });
                  toastr.success("Template updated!");
                } else {
                  throw new Error("Error updating the layouts!");
                }
              })
              .catch(err => {
                const e = err as AxiosError<DashboardBackendAxiosError> | Error;

                let message = "";
                if (axios.isAxiosError(e)) {
                  message = e.response?.data?.msg;
                } else {
                  message = e.message;
                }
                throw new Error(
                  "Error updating the policy!".concat(message || "")
                );
              });
          } else {
            throw new Error(resp.data.msg || "Error updating the policy!");
          }
        })
        .catch(err => {
          const e = err as AxiosError<DashboardBackendAxiosError> | Error;

          let message = "";
          if (axios.isAxiosError(e)) {
            message = e.response?.data?.msg;
          } else {
            message = e.message;
          }
          throw new Error("Error updating the policy!".concat(message || ""));
        });
    } catch (err) {
      const e = err as AxiosError<DashboardBackendAxiosError> | Error;
      let message = "";
      if (axios.isAxiosError(e)) {
        message = e.response?.data?.msg;
      } else {
        message = e.message;
      }
      toastr.error(message);
      dispatch({ type: UPDATE_POLICY_INFO_FAIL });
      dispatch({ type: PATCH_APP_LAYOUT_FAIL });
      if (analytics?.event) {
        AppAnalytics.track(`${analytics.event}.failed`, {
          ...analytics.failAnalyticsData,
          reasonForFailure: message,
        });
      }
      console.error(message);
    }
  };
}

export const FETCH_ALL_TEMPLATES_DONE = "FETCH_ALL_TEMPLATES_DONE";
export const FETCH_ALL_TEMPLATES_INIT = "FETCH_ALL_TEMPLATES_INIT";
export const FETCH_ALL_TEMPLATES_FAIL = "FETCH_ALL_TEMPLATES_FAIL";

export function fetchTemplatesData() {
  return (
    dispatch: AppDispatch,
    getState: () => { roles: { fetchTemplateStatus: string } }
  ) => {
    const { fetchTemplateStatus } = getState().roles;
    if (fetchTemplateStatus === API_CALL_STATE.IN_PROGRESS) {
      return;
    }
    dispatch({ type: FETCH_ALL_TEMPLATES_INIT });
    api
      .service("dashboard")
      .get(getAPIURL("all-templates"))
      .then(async res => {
        if (res.data.success) {
          const all_templates = res?.data?.apps || [];
          dispatch({
            type: FETCH_ALL_TEMPLATES_DONE,
            payload: {
              all_templates,
            },
          });
        } else {
          throw Error("Error fetching app data!");
        }
      })
      .catch(err => {
        console.error(err);
        dispatch({ type: FETCH_ALL_TEMPLATES_FAIL });
        toastr.error(err.message);
      });
  };
}
export const PATCH_POLICY_INFO = "PATCH_POLICY_INFO";
export const patchPolicyInfoToStore = (policy: policyTemplateType) => {
  return (dispatch: AppDispatch) => {
    dispatch({
      type: PATCH_POLICY_INFO,
      payload: policy,
    });
  };
};

export const SET_ACTIVE_ROLE = "SET_ACTIVE_ROLE";
export const setActiveRole = (role: string) => {
  return (dispatch: AppDispatch) => {
    dispatch({
      type: SET_ACTIVE_ROLE,
      payload: role,
    });
  };
};

export const resetActiveRole = () => {
  return setActiveRole("");
};

export const FETCH_POLICY_INFO_DONE = "FETCH_POLICY_INFO_DONE";
export const FETCH_POLICY_INFO_INIT = "FETCH_POLICY_INFO_INIT";
export const FETCH_POLICY_INFO_FAIL = "FETCH_POLICY_INFO_FAIL";

export function fetchPolicyInfo(policy_id: string) {
  return (
    dispatch: AppDispatch,
    getState: () => {
      roles: {
        fetchPolicyInfoStatus: any;
        activeRole: any;
      };
      appLayouts: { roleLayouts: any };
    }
  ) => {
    const fetchPolicyInfoStatus = getState().roles.fetchPolicyInfoStatus;
    const activeRole = getState().roles.activeRole;
    if (fetchPolicyInfoStatus === API_CALL_STATE.IN_PROGRESS) {
      return;
    }
    dispatch({
      type: FETCH_POLICY_INFO_INIT,
      payload: { policyId: policy_id },
    });
    fetchPolicy(policy_id)
      .then(async res => {
        if (res.data.success) {
          const policyInfo = res.data.data as policyTemplateType;
          const rtmpToggle = Object.keys(
            policyInfo.destinations?.rtmpDestinations ?? {}
          ).length;
          Object.keys(policyInfo?.roles).forEach(role => {
            //push role permissions to each role object

            (policyInfo.roles[role] as roleType).permissions = {
              ...roleTemplateForPolicy.permissions,
              ...(policyInfo.roles[role] as roleType).permissions,
            };
          });
          dispatch({
            type: FETCH_POLICY_INFO_DONE,
            payload: {
              policyInfo,
            },
          });

          dispatch(
            //@ts-ignore
            setHLSDestination()
          );
          dispatch({
            type: SET_TRANSCRIPTION_ENABLE_FLAG,
          });
          dispatch({ type: SET_RTMP_DESTINATIONS, payload: !!rtmpToggle });
          if (
            !activeRole ||
            !Object.keys(policyInfo?.roles).includes(activeRole)
          ) {
            dispatch(
              //@ts-ignore
              setActiveRole(getVisibleRoles(Object.keys(policyInfo?.roles))[0])
            );
          }
        } else {
          throw Error("Error fetching app data!");
        }
      })
      .catch(err => {
        dispatch({ type: FETCH_POLICY_INFO_FAIL });
        console.error(err.message);
        toastr.error(err.message);
      });
  };
}

export const UPDATE_POLICY_INFO_DONE = "UPDATE_POLICY_INFO_DONE";
export const UPDATE_POLICY_INFO_INIT = "UPDATE_POLICY_INFO_INIT";
export const UPDATE_POLICY_INFO_FAIL = "UPDATE_POLICY_INFO_FAIL";
export function updatePolicyInfo(
  policy_id: string,
  data: policyTemplateType,
  [event, successAnalyticsData, failAnalyticsData] = [null, {}, {}]
) {
  return (dispatch: AppDispatch) => {
    dispatch({ type: UPDATE_POLICY_INFO_INIT });
    try {
      updatePolicy({ policy_id, data })
        .then(async res => {
          if (res.data.success) {
            if (event) {
              AppAnalytics.track(`${event}.success`, successAnalyticsData);
            }
            toastr.success("Template updated!");
            dispatch({ type: UPDATE_POLICY_INFO_DONE, payload: data });
          } else {
            throw Error("Error updating the fields!");
          }
        })
        .catch(err => {
          const e = err as AxiosError<DashboardBackendAxiosError> | Error;
          let message = "";
          if (axios.isAxiosError(e)) {
            message = e.response?.data?.msg;
          } else {
            message = e.message;
          }
          if (event) {
            AppAnalytics.track(`${event}.failed`, {
              ...failAnalyticsData,
              reasonForFailure: message,
            });
          }
          console.error(err);
          dispatch({ type: UPDATE_POLICY_INFO_FAIL });
          toastr.error(message);
        });
    } catch (err) {
      const e = err as AxiosError<DashboardBackendAxiosError> | Error;

      let message = "";
      if (axios.isAxiosError(e)) {
        message = e.response?.data?.msg;
      } else {
        message = e.message;
      }
      if (event) {
        AppAnalytics.track(`${event}.failed`, {
          ...failAnalyticsData,
          reasonForFailure: message,
        });
      }
      console.error(err);
      dispatch({ type: UPDATE_POLICY_INFO_FAIL });
      toastr.error(message);
    }
  };
}

export const DELETE_POLICY_INFO_DONE = "DELETE_POLICY_INFO_DONE";
export const DELETE_POLICY_INFO_INIT = "DELETE_POLICY_INFO_INIT";
export const DELETE_POLICY_INFO_FAIL = "DELETE_POLICY_INFO_FAIL";
export const DELETE_POLICY_INFO_RESET = "DELETE_POLICY_INFO_RESET";
export function deletePolicyInfo(policy_id: string) {
  return (
    dispatch: AppDispatch,
    getState: () => { roles: { deletePolicyStatus: string } }
  ) => {
    const { deletePolicyStatus } = getState().roles;
    if (deletePolicyStatus === API_CALL_STATE.IN_PROGRESS) {
      return;
    }
    dispatch({ type: DELETE_POLICY_INFO_INIT });
    api
      .service("dashboard")
      .post("delete-template", { policy_id: policy_id })
      .then(async res => {
        if (res.data.success) {
          toastr.success("Template deleted!");
          dispatch({ type: DELETE_POLICY_INFO_DONE, payload: policy_id });
        } else {
          throw Error("Error deleting the template!");
        }
      })
      .catch(err => {
        dispatch({ type: DELETE_POLICY_INFO_FAIL });
        toastr.error(err.message);
      });
  };
}

export const CHECK_SUBDOMAIN_DONE = "CHECK_SUBDOMAIN_DONE";
export const CHECK_SUBDOMAIN_INIT = "CHECK_SUBDOMAIN_INIT";
export const CHECK_SUBDOMAIN_FAIL = "CHECK_SUBDOMAIN_FAIL";
export const CHECK_SUBDOMAIN_RESET = "CHECK_SUBDOMAIN_RESET";
export const CHECK_SUBDOMAIN_CANCEL = "CHECK_SUBDOMAIN_CANCEL";
export const CHECK_SUBDOMAIN_CANCEL_FUNCTION =
  "CHECK_SUBDOMAIN_CANCEL_FUNCTION";

export function checkSubdomain(subdomain: string) {
  return (
    dispatch: AppDispatch,
    getState: () => { roles: { checkSubdomainStatus: string } }
  ) => {
    const checkSubdomainStatus = getState().roles.checkSubdomainStatus;
    if (checkSubdomainStatus === API_CALL_STATE.IN_PROGRESS || !subdomain) {
      return;
    }
    const apiInstance = api.service("dashboard");
    dispatch({
      type: CHECK_SUBDOMAIN_CANCEL_FUNCTION,
      payload: apiInstance.cancelRequest,
    });
    dispatch({ type: CHECK_SUBDOMAIN_INIT });
    apiInstance
      .get("subdomain/check", {
        subdomain_name: subdomain,
      })
      .then(res => {
        if (!res.data.success) {
          throw Error(res.data.msg || "Already in use!");
        } else {
          dispatch({
            type: CHECK_SUBDOMAIN_DONE,
            payload: { error: "", subdomain: subdomain },
          });
        }
      })
      .catch(error => {
        const result = error as AxiosError | Error;
        const isCancel = axios.isCancel(result);

        dispatch({
          type: CHECK_SUBDOMAIN_FAIL,
          payload: {
            error: isCancel ? "" : error.message || "Subdomain not available",
            subdomain: subdomain,
          },
        });
      });
  };
}

export function cancelCheckSubdomainName() {
  return (
    dispatch: AppDispatch,
    getState: () => {
      roles: { checkSubdomainAPICancelFunction: () => {} };
    }
  ) => {
    const checkSubdomainAPICancelFunction =
      getState()?.roles?.checkSubdomainAPICancelFunction;
    checkSubdomainAPICancelFunction();
    dispatch({ type: CHECK_SUBDOMAIN_CANCEL });
  };
}

export const ROLES_REQUEST_INIT = "ROLES_REQUEST_INIT";
export const ROLES_REQUEST_DONE = "ROLES_REQUEST_DONE";
export const ROLES_REQUEST_FAIL = "ROLES_REQUEST_FAIL";
export function fetchRolesData(policyId: string) {
  return (
    dispatch: AppDispatch,
    getState: () => {
      roles: {
        fetchRolesStatus: string;
      };
    }
  ) => {
    const { fetchRolesStatus } = getState().roles;

    if (fetchRolesStatus === API_CALL_STATE.IN_PROGRESS) {
      return;
    }

    dispatch({ type: ROLES_REQUEST_INIT });
    api
      .service("dashboard")
      .get(getAPIURL(`roles/get-roles/${policyId}`))
      .then(res => {
        if (res.data.success) {
          const { data } = res.data;
          const roles = data;
          dispatch({
            type: ROLES_REQUEST_DONE,
            payload: {
              roles,
              policy: res.data.policy,
            },
          });
        } else {
          throw new Error(res.data.msg);
        }
      })
      .catch((err: Error) => {
        const e = err as AxiosError<DashboardBackendAxiosError> | Error;

        let message = "";
        if (axios.isAxiosError(e)) {
          message = e.response?.data?.msg;
        } else {
          message = e.message;
        }
        console.error("Error in fetching response ", message);
        toastr.error(message);
        dispatch({ type: ROLES_REQUEST_FAIL });
        AppAnalytics.track("fetch.role.failure", {
          page: "/roles",
          policyId: policyId,
          // app_type: params.app_type,
          // app_name: params.app_name,
        });
      });
  };
}

export const PATCH_ROLES_IN_STORE = "PATCH_ROLES_IN_STORE";
export const patchRolesInStore = (roles: roleTemplatePolicy[]) => {
  return (dispatch: AppDispatch) => {
    dispatch({ type: PATCH_ROLES_IN_STORE, payload: { roles: roles } });
  };
};

export const PATCH_ROLE_IN_STORE = "PATCH_ROLE_IN_STORE";
export const patchRoleInStore = (role: roleTemplatePolicy) => {
  return (dispatch: AppDispatch) => {
    dispatch({ type: PATCH_ROLE_IN_STORE, payload: { role: role } });
  };
};

export const patchRoleNameInStore = (
  role: roleTemplatePolicy,
  name: string
) => {
  return (
    dispatch: AppDispatch,
    getState: () => {
      roles: {
        policyInfo: policyTemplateType;
        policyId: string;
      };
    }
  ) => {
    const { policyInfo } = getState().roles;
    //get state to process the name change
    const roles: Record<string, roleType> = getState().roles.policyInfo
      .roles as unknown as Record<string, roleType>;
    const policyId = getState().roles.policyId;

    const roleObject: Record<string, roleType> = { [role.name]: role };
    // delete role object from the state
    delete roles[name];
    // create role object for policy with update name
    const newRoles: Record<string, roleType> = { ...roles, ...roleObject };
    Object.keys(newRoles).forEach((key: string) => {
      // check wether the prev role exists in any index if yes then replace it with the new name
      const roleIndex = newRoles?.[
        key
      ]?.subscribeParams?.subscribeToRoles?.findIndex(
        (roleName: string) => roleName === name
      );

      if (roleIndex >= 0) {
        //new array for the subscribed roles
        const tempArray = newRoles?.[
          key
        ]?.subscribeParams?.subscribeToRoles?.filter(
          (roleName: string) => roleName !== name
        );
        newRoles[key].subscribeParams.subscribeToRoles = [];
        newRoles[key].subscribeParams.subscribeToRoles = [...tempArray];
        newRoles[key].subscribeParams.subscribeToRoles.push(role.name);
      }
    });
    delete newRoles[name];

    //handle plugins case for the role

    const plugins = { ...policyInfo.plugins };
    if (!isEmpty(plugins?.whiteboard)) {
      let whiteboardPermissions = (plugins?.whiteboard?.permissions ||
        {}) as whiteboardPermissionsType;
      Object.keys(whiteboardPermissions).forEach((key: string) => {
        if (
          whiteboardPermissions?.[key as keyof typeof whiteboardPermissions] &&
          (
            whiteboardPermissions?.[
              key as keyof typeof whiteboardPermissions
            ] || []
          )?.length > 0 &&
          isArray(
            whiteboardPermissions[key as keyof typeof whiteboardPermissions]
          )
        ) {
          const index = whiteboardPermissions[
            key as keyof typeof whiteboardPermissions
          ]?.findIndex((role: string) => role === name);
          const tempArray = cloneDeep(
            whiteboardPermissions[key as keyof typeof whiteboardPermissions]
          ) as string[];
          if (index !== undefined && index >= 0) {
            tempArray[index] = role.name;
            whiteboardPermissions = {
              ...whiteboardPermissions,
              [key as keyof typeof whiteboardPermissions]: [...tempArray],
            };
          }
        }
      });
      plugins.whiteboard.permissions = whiteboardPermissions;
    }
    try {
      const rolesObject = Object.values(newRoles).reduce((acc, curr) => {
        acc[curr.name as keyof typeof acc] = curr;
        return acc;
      }, {} as Record<string, roleType>);
      //@ts-ignore
      dispatch(patchRolesInStore(Object.values(newRoles)));
      //@ts-ignore
      dispatch(updateMultipleRoles(policyId, Object.values(newRoles)));
      if (!isEmpty(plugins?.whiteboard)) {
        dispatch(
          //@ts-ignore
          updatePolicyInfo(policyId, {
            ...policyInfo,
            roles: { ...rolesObject },
            plugins: { ...plugins },
          })
        );
      }
    } catch (e) {
      //@ts-ignore
      dispatch(fetchRolesData(policyId));
    }
  };
};

export const UPDATE_ROLES_INIT = "UPDATE_ROLES_INIT";
export const UPDATE_ROLES_DONE = "UPDATE_ROLES_DONE";
export const UPDATE_ROLES_FAIL = "UPDATE_ROLES_FAIL";
//eslint-disable-next-line
export const updateRole = (
  policyId: string,
  roleInfo: roleTemplatePolicy,
  roles: roleTemplatePolicy[]
) => {
  return (dispatch: AppDispatch) => {
    dispatch({ type: UPDATE_ROLES_INIT });
    api
      .service("dashboard")
      .post(`roles/update-role/${policyId}`, { roleInfo })
      .then(res => {
        if (res.data.success) {
          // update role as per response
          const selectedRole = findIndex(
            roles,
            role => role.name === roleInfo.name
          );

          api
            .service("dashboard")
            .get(getAPIURL("get-policy-info"), { policy_id: policyId })
            .then(async res => {
              if (res.data.success) {
                const policyInfo = res.data.data;
                if (
                  roles[selectedRole].permissions.sendRoomState &&
                  !policyInfo?.settings?.roomState?.enabled
                ) {
                  policyInfo.settings.roomState.enabled = true;
                  policyInfo.settings.roomState.messageInterval =
                    policyInfo?.settings?.roomState?.messageInterval || 10;
                  //@ts-ignore
                  dispatch(updatePolicyInfo(policyId, policyInfo));
                }
              } else {
                throw Error("Error while updating template");
              }
            });

          roles[selectedRole] = res.data.role;
          if (roleInfo.name === "__internal_recorder") {
            toastr.success("Template updated!");
          }
          dispatch({ type: UPDATE_ROLES_DONE, payload: { roles } });
          AppAnalytics.track("change.role.setting.success", {
            // app_type: app_type,
            // app_name: app_name,
            policy_id: policyId,
            role_name: roleInfo.name,
          });
        } else {
          throw new Error(res?.data?.msg);
        }
      })
      .catch((err: Error | AxiosError) => {
        const e = err as AxiosError<DashboardBackendAxiosError> | Error;

        let message = "";
        if (axios.isAxiosError(e)) {
          message = e.response?.data?.msg;
        } else {
          message = e.message;
        }
        console.error("Error in fetching response ", message);
        toastr.error(message);
        dispatch({ type: UPDATE_ROLES_FAIL });
        AppAnalytics.track("change.role.setting.failure", {
          // app_type: app_type,
          // app_name: app_name,
          policy_id: policyId,
          role_name: roleInfo.name,
        });
      });
  };
};

export const updateMultipleRoles = (
  policy_id: string,
  roles: roleTemplatePolicy[]
) => {
  return (dispatch: AppDispatch) => {
    dispatch({ type: UPDATE_ROLES_INIT });
    api
      .service("dashboard")
      .post(`roles/update-multiple-roles/${policy_id}`, { roles })
      .then(res => {
        if (res.data.success) {
          dispatch({ type: UPDATE_ROLES_DONE, payload: { roles } });
          AppAnalytics.track("change.roomstate.roles.setting.success", {
            policy_id,
          });
        } else {
          throw new Error(res?.data?.msg);
        }
      })
      .catch((err: Error | AxiosError) => {
        const e = err as AxiosError<DashboardBackendAxiosError> | Error;

        let message = "";
        if (axios.isAxiosError(e)) {
          message = e.response?.data?.msg;
        } else {
          message = e.message;
        }
        console.error("Error in fetching response ", message);
        toastr.error(message);
        dispatch({ type: UPDATE_ROLES_FAIL });
        AppAnalytics.track("change.roomstate.roles.setting.failure", {
          policy_id,
        });
      });
  };
};

export const ADD_ROLES_INIT = "ADD_ROLES_INIT";
export const ADD_ROLES_DONE = "ADD_ROLES_DONE";
export const ADD_ROLES_FAIL = "ADD_ROLES_FAIL";
export const addRole = (policyId: string, roleInfo: roleTemplatePolicy) => {
  return (
    dispatch: AppDispatch,
    getState: () => { roles: { roles: roleTemplatePolicy[] } }
  ) => {
    dispatch({ type: ADD_ROLES_INIT });
    api
      .service("dashboard")
      .post(`roles/create-role/${policyId}`, { roleInfo })
      .then(res => {
        if (res.data.success) {
          const roles = cloneDeep(getState().roles.roles);
          roles.push(roleInfo);
          dispatch({ type: ADD_ROLES_DONE, payload: { roles: roles } });
          AppAnalytics.track("add.role.success", {
            page: "/roles",
            role_name: roleInfo.name,
          });
          if (
            roleInfo.name !== HLS_VIEWER_ROLE &&
            isVisibleRole(roleInfo.name)
          ) {
            toastr.success("Role created successfully!");
            dispatch(
              //@ts-ignore
              setActiveRole(roleInfo.name)
            );
          }
        } else {
          throw new Error(res?.data?.msg);
        }
      })
      .catch((err: Error | AxiosError) => {
        const e = err as AxiosError<DashboardBackendAxiosError> | Error;

        let message = "";
        if (axios.isAxiosError(e)) {
          message = e.response?.data?.msg;
        } else {
          message = e.message;
        }
        console.error("Error in fetching response ", message);
        dispatch({ type: ADD_ROLES_FAIL });
        toastr.error(message);
        AppAnalytics.track("add.role.failure", {
          page: "/roles",
          role_name: roleInfo.name,
        });
      });
  };
};

export const DELETE_ROLES_INIT = "DELETE_ROLES_INIT";
export const DELETE_ROLES_DONE = "DELETE_ROLES_DONE";
export const DELETE_ROLES_FAIL = "DELETE_ROLES_FAIL";
export const deleteRole = (
  policyId: string,
  roles: roleTemplatePolicy[],
  role_name: string
) => {
  return (dispatch: AppDispatch) => {
    dispatch({ type: DELETE_ROLES_INIT });
    api
      .service("dashboard")
      .post(`roles/delete-role/${policyId}`, { role: role_name })
      .then(res => {
        if (res.data.success) {
          let filteredRolesInSubscribe = [];
          roles.forEach(role => {
            filteredRolesInSubscribe =
              role.subscribeParams.subscribeToRoles?.filter(
                subscribed_role => subscribed_role !== role_name
              );
            role.subscribeParams.subscribeToRoles = filteredRolesInSubscribe;
          });
          dispatch({ type: DELETE_ROLES_DONE, payload: { roles: roles } });

          if (role_name !== HLS_VIEWER_ROLE && isVisibleRole(role_name)) {
            toastr.success("Role deleted successfully!");
          }
          AppAnalytics.track("delete.role.success", {
            policy_id: policyId,
            // app_type: params.app_type,
            // app_name: params.app_name,
            role_name: role_name,
          });

          dispatch(
            //@ts-ignore
            setActiveRole(
              getVisibleRoles(roles.map(role => role.name)).filter(
                (role: string) => role !== role_name
              )[0]
            )
          );
        } else {
          throw new Error(res.data.msg);
        }
      })
      .catch((err: AxiosError | Error) => {
        const e = err as AxiosError<DashboardBackendAxiosError> | Error;

        let message = "";
        if (axios.isAxiosError(e)) {
          message = e.response?.data?.msg;
        } else {
          message = e.message;
        }
        console.error("Error in fetching response ", message);
        dispatch({ type: DELETE_ROLES_FAIL });
        toastr.error(message);
        AppAnalytics.track("delete.role.failure", {
          policy_id: policyId,
          // app_type: params.app_type,
          // app_name: params.app_name,
          role_name: role_name,
        });
      });
  };
};

export const PUT_DESTINATIONS = "PUT_DESTINATIONS";
export const UPDATE_DESTINATION_INIT = "UPDATE_DESTINATION_INIT";
export const UPDATE_DESTINATION_DONE = "UPDATE_DESTINATION_DONE";
export const UPDATE_DESTINATION_FAIL = "UPDATE_DESTINATION_FAIL";

export const updateDestination = (
  policyId = "",
  policyData: policyTemplateType = {
    id: "",
    name: "",
    customer: "",
    roles: {},
    settings: {},
    destinations: {},
  }
) => {
  // eslint-disable-next-line complexity
  return async (dispatch: AppDispatch) => {
    dispatch({
      type: UPDATE_DESTINATION_INIT,
    });
    const hlsDestinationName = Object.keys(
      (policyData?.destinations?.hlsDestinations || {}) as Record<
        string,
        hlsDestinationsType
      >
    )?.[0];

    if (
      isArray(
        policyData?.destinations?.hlsDestinations?.[hlsDestinationName]?.layers
      ) &&
      (
        policyData?.destinations?.hlsDestinations?.[hlsDestinationName]
          .layers as any[]
      ).length > 0 &&
      hlsDestinationName
    ) {
      const templayers: hlsDestinationsLayerType[] = (
        policyData?.destinations?.hlsDestinations?.[hlsDestinationName]
          ?.layers as hlsDestinationsLayerType[]
      )?.sort(
        (b: hlsDestinationsLayerType, a: hlsDestinationsLayerType) =>
          a?.width - b?.width
      );

      //@ts-ignore
      policyData.destinations.hlsDestinations[hlsDestinationName].layers =
        templayers as hlsDestinationsLayerType[];
    }

    try {
      const res = await api
        .service("dashboard")
        .post("update-app-v2", { policy_id: policyId, data: policyData });
      if (res.data.success) {
        toastr.success("Template updated!");
      } else {
        throw new Error("Template update failed!");
      }
    } catch (err) {
      const e = err as AxiosError<DashboardBackendAxiosError> | Error;

      let message = "";
      if (axios.isAxiosError(e)) {
        message = e.response?.data?.msg;
      } else {
        message = e.message;
      }
      console.error(message);
      dispatch({ type: UPDATE_DESTINATION_FAIL });
      toastr.error(message);
    }

    return dispatch({
      type: UPDATE_DESTINATION_DONE,
    });
  };
};

export const changeRoleName = (
  policyId: string,
  old_name: string,
  new_name: string,
  newRoles: roleTemplatePolicy[]
) => {
  return (dispatch: AppDispatch) => {
    dispatch({ type: UPDATE_ROLES_INIT });
    return api
      .service("dashboard")
      .post(`roles/change-role-name/${policyId}`, { old_name, new_name })
      .then(response => {
        if (!response.data.success) {
          throw new Error(response?.data?.msg);
        }
        toastr.success("Template updated!");
        dispatch({ type: UPDATE_ROLES_DONE, payload: { roles: newRoles } });
        AppAnalytics.track("change.role_name.setting.success", {
          policy_id: policyId,
          new_name,
          old_name,
        });
      })
      .catch((error: Error | AxiosError) => {
        const e = error as AxiosError<DashboardBackendAxiosError> | Error;

        let message = "";
        if (axios.isAxiosError(e)) {
          message = e.response?.data?.msg;
        } else {
          message = e.message;
        }
        toastr.error("Could not change the role name");
        console.error(message);
        dispatch({ type: UPDATE_ROLES_FAIL });
      });
  };
};

export const UPDATE_TEMPLATE_INIT = "UPDATE_TEMPLATE_INIT";
export const UPDATE_TEMPLATE_DONE = "UPDATE_TEMPLATE_DONE";
export const UPDATE_TEMPLATE_FAIL = "UPDATE_TEMPLATE_FAIL";

export const updateTemplate = ({
  policyId,
  subdomain_name,
  analytics,
}: {
  policyId: string;
  subdomain_name: string;
  analytics: any;
}) => {
  //eslint-disable-next-line
  return async (
    dispatch: AppDispatch,
    getState: () => { roles: { updateTemplateStatus: string } }
  ) => {
    const { updateTemplateStatus } = getState().roles;
    if (updateTemplateStatus === API_CALL_STATE.IN_PROGRESS) {
      return;
    }
    dispatch({
      type: UPDATE_TEMPLATE_INIT,
    });
    try {
      const res = await api.service("dashboard").patch("app/update", {
        template_id: policyId,
        subdomain_name: subdomain_name,
        subdomain: subdomain_name,
      });
      if (res.data.success) {
        if (analytics?.event) {
          AppAnalytics.track(`${analytics?.event}.success`, analytics.success);
        }
        toastr.success(res?.data?.msg);
      } else {
        throw Error(res?.data?.msg);
      }
    } catch (e: any) {
      const message = e.response?.data?.msg || e.message;
      if (analytics?.event) {
        AppAnalytics.track(`${analytics?.event}.failed`, analytics.failed);
      }
      console.error(message);
      dispatch({ type: UPDATE_TEMPLATE_FAIL });
      toastr.error(message);
    }
    return dispatch({
      type: UPDATE_TEMPLATE_DONE,
      payload: {
        id: policyId,
        subdomain_name,
        updateDestinationStatus: API_CALL_STATE.DONE,
      },
    });
  };
};

export const REQUESTS_COMPLETE = "REQUESTS_COMPLETE";
export const REPLACE_ROLES_ONBOARDING = "REPLACE_ROLES_ONBOARDING";

export const UPDATE_HLS_DESTINATIONS = "UPDATE_HLS_DESTINATIONS";
export const updateHLSDestinations = (payload: hlsDestinationsType) => {
  return (
    dispatch: AppDispatch,
    getState: () => { roles: { policyInfo: policyTemplateType } }
  ) => {
    const { policyInfo } = getState().roles;

    const [destinationName] = Object.keys(
      policyInfo?.destinations?.hlsDestinations as Record<
        string,
        hlsDestinationsType
      >
    );
    dispatch({
      type: UPDATE_HLS_DESTINATIONS,
      payload,
    });
    //@ts-ignore
    dispatch(validateHLSDestinations(destinationName));
  };
};

export const VALIDATE_HLS_DESTINATIONS = "VALIDATE_HLS_DESTINATIONS";
export const validateHLSDestinations = (destinationName: string) => {
  return (
    dispatch: AppDispatch,
    getState: () => { roles: { policyInfo: policyTemplateType } }
  ) => {
    const { policyInfo } = getState().roles;
    const { destinations = {} } = policyInfo;
    const { hlsDestinations = {} } = destinations;
    const validationResult = validateHLSdestinationFields({
      hlsDestinations: hlsDestinations?.[destinationName],
      hlsRole: policyInfo?.roles?.[INTERNAL_RECORDER_ROLE],
    });
    dispatch({
      type: VALIDATE_HLS_DESTINATIONS,
      payload: validationResult.invalidFields,
    });
  };
};

export const CLEAR_VALIDATIONS_RESULTS = "CLEAR_VALIDATIONS_RESULTS";
export const clearValidationResults = () => {
  return (dispatch: AppDispatch) => {
    dispatch({
      type: CLEAR_VALIDATIONS_RESULTS,
    });
  };
};

export const resetHLSDestinationsValidation = () => {
  return (dispatch: AppDispatch) => {
    dispatch({
      type: VALIDATE_HLS_DESTINATIONS,
      payload: {},
    });
  };
};
export const UPDATE_PLUGINS_INFO = "UPDATE_PLUGINS_INFO";

export const updatePlugins = (payload: templatePluginTypes) => {
  return (dispatch: AppDispatch) => {
    // update the recording info in store
    dispatch({
      type: UPDATE_PLUGINS_INFO,
      payload,
    });
    // then validate it
    //@ts-ignore
    dispatch(validatePlugins());
  };
};

export const UPDATE_RECORDING_INFO = "UPDATE_RECORDING_INFO";
export const updateRecordingInfo = (
  payload: policyTemplateType["settings"]["recording"]
) => {
  return (dispatch: AppDispatch) => {
    // update the recording info in store
    dispatch({
      type: UPDATE_RECORDING_INFO,
      payload,
    });
    // then validate it
    //@ts-ignore
    dispatch(validateRecordingInfo());
  };
};

export const VALIDATE_RECORDING_INFO = "VALIDATE_RECORDING_INFO";
export const validateRecordingInfo = () => {
  return (
    dispatch: AppDispatch,
    getState: () => { roles: { policyInfo: policyTemplateType } }
  ) => {
    const { policyInfo } = getState().roles;
    const { settings = {} } = policyInfo;
    const { recording: recordingInfo = {} } = settings;
    const validationResult = validateRecordings(recordingInfo);
    dispatch({
      type: VALIDATE_RECORDING_INFO,
      payload: validationResult.invalidFields,
    });
  };
};

export const VALIDATE_PLUGINS_INFO = "VALIDATE_PLUGINS_INFO";
export const validatePlugins = () => {
  return (
    dispatch: AppDispatch,
    getState: () => { roles: { policyInfo: policyTemplateType } }
  ) => {
    const { policyInfo } = getState().roles;
    const { plugins = {} } = policyInfo;

    const validationResult = validatePluginsInfo(plugins);
    if (!validationResult.isValid) {
      //@ts-ignore
      dispatch(setHasUnsavedSettings(false));
    }
    dispatch({
      type: VALIDATE_PLUGINS_INFO,
      payload: validationResult.invalidFields,
    });
  };
};

export const resetRecordingInfoValidation = () => {
  return (dispatch: AppDispatch) => {
    dispatch({
      type: VALIDATE_RECORDING_INFO,
      payload: {},
    });
  };
};

export const UNSAVED_ADVANCED_SETTINGS_FLAG = "UNSAVED_ADVANCED_SETTINGS_FLAG";
export const setHasUnsavedSettings = (hasUnsavedAdvancedSettings: boolean) => {
  return (dispatch: AppDispatch) => {
    dispatch({
      type: UNSAVED_ADVANCED_SETTINGS_FLAG,
      payload: hasUnsavedAdvancedSettings,
    });
  };
};

export const UNSAVED_RECORDINGS_FLAG = "UNSAVED_RECORDINGS_FLAG";
export const setHasUnsavedRecordingSettings = (
  hasUnsavedRecordingSettings: boolean
) => {
  return (dispatch: AppDispatch) => {
    dispatch({
      type: UNSAVED_RECORDINGS_FLAG,
      payload: hasUnsavedRecordingSettings,
    });
  };
};

export const UNSAVED_ROLES_FLAG = "UNSAVED_ROLES_FLAG";
export const setHasUnsavedRoles = (hasUnsavedRoles: boolean) => {
  return (dispatch: AppDispatch) => {
    dispatch({
      type: UNSAVED_ROLES_FLAG,
      payload: hasUnsavedRoles,
    });
  };
};

export const UNSAVED_LIVE_STREAMING_FLAG = "UNSAVED_LIVE_STREAMING_FLAG";
export const setHasUnsavedLiveStreamingChanges = (
  hasUnsavedLiveStreamingChanges: boolean
) => {
  return (dispatch: AppDispatch) => {
    dispatch({
      type: UNSAVED_LIVE_STREAMING_FLAG,
      payload: hasUnsavedLiveStreamingChanges,
    });
  };
};

export const UNSAVED_TRANSCRIPTION_FLAG = "UNSAVED_TRANSCRIPTION_FLAG";
export const setHasUnsavedTranscriptionChanges = (
  hasUnsavedTranscriptionChanges: boolean
) => {
  return (dispatch: AppDispatch) => {
    dispatch({
      type: UNSAVED_TRANSCRIPTION_FLAG,
      payload: hasUnsavedTranscriptionChanges,
    });
  };
};

export const UPDATE_RECORDINGS_CONFIG = "UPDATE_RECORDINGS_CONFIG";
export const updateRecordingsConfig = ({
  recordingConfig,
}: {
  recordingConfig: RecordingConfigType;
}) => {
  return (dispatch: AppDispatch) => {
    dispatch({
      type: UPDATE_RECORDINGS_CONFIG,
      payload: { recordingConfig },
    });
    //@ts-ignore
    dispatch(validateRecordingsConfig());
  };
};

export const UPDATE_TRANSCRIPTION = "UPDATE_TRANSCRIPTION";
export const updateTranscription = ({
  transcription,
  name,
}: {
  transcription: TranscriptionType;
  name: string;
}) => {
  return (dispatch: AppDispatch) => {
    dispatch({
      type: UPDATE_TRANSCRIPTION,
      payload: { transcription: transcription, name: name },
    });
    //@ts-ignore
    dispatch(validateTranscription());
  };
};

export const VALIDATE_TRANSCRIPTION = "VALIDATE_TRANSCRIPTION";
export const validateTranscription = () => {
  return (
    dispatch: AppDispatch,
    getState: () => { roles: { policyInfo: policyTemplateType } }
  ) => {
    const { policyInfo } = getState().roles;
    const { destinations = {} } = policyInfo;
    const { transcriptions = {} } = destinations;
    const transcriptionIds = Object.keys(transcriptions);
    const validationResult = validateTranscriptionFields({
      transcription: transcriptions?.[transcriptionIds[0]],
    });

    dispatch({
      type: VALIDATE_TRANSCRIPTION,
      payload: validationResult.invalidFields,
    });
    if (isEmpty(validationResult.invalidFields) && validationResult.isValid) {
      //@ts-ignore
      dispatch(setHasUnsavedTranscriptionChanges(true));
    } else {
      //@ts-ignore
      dispatch(setHasUnsavedTranscriptionChanges(false));
    }
  };
};

export const VALIDATE_RECORDINGS_CONFIG = "VALIDATE_RECORDINGS_CONFIG";
export const validateRecordingsConfig = () => {
  return (
    dispatch: AppDispatch,
    getState: () => { roles: { policyInfo: policyTemplateType } }
  ) => {
    const { policyInfo } = getState().roles;
    const { recordings = [] } = policyInfo;
    const validationResult = validateRecordingsConfigFields({
      recordingConfig: recordings?.[0],
    });

    dispatch({
      type: VALIDATE_RECORDINGS_CONFIG,
      payload: validationResult.invalidFields,
    });
  };
};

export const DISABLE_BROWSER_COMPOSITE = "DISABLE_BROWSER_COMPOSITE";
export const ENABLE_BROWSER_COMPOSITE = "ENABLE_BROWSER_COMPOSITE";
export const toggleBrowserComposite = (enableFlag: boolean) => {
  return (dispatch: AppDispatch, getState: () => RootState) => {
    // select the dispatch type
    const type = enableFlag
      ? ENABLE_BROWSER_COMPOSITE
      : DISABLE_BROWSER_COMPOSITE;
    dispatch({ type });

    const transcriptions =
      getState().roles.policyInfo?.destinations?.transcriptions;

    if (!isEmpty(transcriptions)) {
      dispatch({ type: DISABLE_TRANSCRIPTION, payload: "recorded" });
    }
  };
};

export const DISABLE_TRACK_RECORDING = "DISABLE_TRACK_RECORDING";
export const ENABLE_TRACK_RECORDING = "ENABLE_TRACK_RECORDING";
export const toggleTrackRecording = (enableFlag: boolean) => {
  return (dispatch: AppDispatch) => {
    // select the dispatch type
    const type = enableFlag ? ENABLE_TRACK_RECORDING : DISABLE_TRACK_RECORDING;
    dispatch({ type });
  };
};

export const DISABLE_STREAM_RECORDING = "DISABLE_STREAM_RECORDING";
export const ENABLE_STREAM_RECORDING = "ENABLE_STREAM_RECORDING";
export const toggleStreamRecording = (enableFlag: boolean) => {
  return (dispatch: AppDispatch) => {
    // select the dispatch type
    const type = enableFlag
      ? ENABLE_STREAM_RECORDING
      : DISABLE_STREAM_RECORDING;
    dispatch({ type });
  };
};

export const SET_TRANSCRIPTION_ENABLE_FLAG = "SET_TRANSCRIPTION_ENABLE_FLAG";
export const DISABLE_TRANSCRIPTION = "DISABLE_TRANSCRIPTION";
export const ENABLE_TRANSCRIPTION = "ENABLE_TRANSCRIPTION";
export const toggleTranscription = (enableFlag: boolean, mode: string) => {
  return (dispatch: AppDispatch) => {
    // select the dispatch type
    const type = enableFlag ? ENABLE_TRANSCRIPTION : DISABLE_TRANSCRIPTION;
    dispatch({ type, payload: mode });
    if (mode === "recorded") {
      //@ts-ignore
      dispatch(validateTranscription());
    }
  };
};

export const SET_HLS_DESTINATION_ENABLE_FLAG =
  "SET_HLS_DESTINATION_ENABLE_FLAG";

export const setHLSDestination = () => {
  return (dispatch: AppDispatch, getState: () => RootState) => {
    const roleLayouts = getState().appLayouts.roleLayouts;
    const fetchAppLayoutStatus = getState().appLayouts.fetchAppLayoutStatus;
    const policyInfo = getState().roles.policyInfo;
    if (fetchAppLayoutStatus !== API_CALL_STATE.IN_PROGRESS) {
      if (!isEmpty(policyInfo.roles)) {
        dispatch({
          type: SET_HLS_DESTINATION_ENABLE_FLAG,
          payload: roleLayouts,
        });
      }
    }
  };
};
export const DISABLE_HLS_DESTINATION = "DISABLE_HLS_DESTINATION";
export const ENABLE_HLS_DESTINATION = "ENABLE_HLS_DESTINATION";
export const toggleHLSDestination = (enableFlag: boolean) => {
  // eslint-disable-next-line complexity
  return (
    dispatch: AppDispatch,
    getState: () => { appLayouts: { roleLayouts: any } }
  ) => {
    const roleLayouts = getState().appLayouts.roleLayouts;
    // select the dispatch type
    const type = enableFlag ? ENABLE_HLS_DESTINATION : DISABLE_HLS_DESTINATION;
    const isOldTemplate = isEmpty(roleLayouts);
    dispatch({
      type,
      payload: !isOldTemplate ? roleLayouts : undefined,
    });

    if (!isOldTemplate) {
      if (enableFlag) {
        const oldHLSViewerRoles = getHLSViewerRolesFromRoleLayouts({
          roleLayouts: roleLayouts,
        });

        const hasOldHLSViewerRole =
          oldHLSViewerRoles && oldHLSViewerRoles?.length > 0;
        if (!hasOldHLSViewerRole) {
          const hlsViewerLayout = getHLSViewerRoleLayout({
            roleName: NEW_HLS_VIEWER_ROLE,
            roleLayouts: roleLayouts,
          });
          dispatch(
            //@ts-ignore
            updateAppLayoutInStore({
              roleLayouts: {
                ...roleLayouts,
                [NEW_HLS_VIEWER_ROLE]: {
                  ...roleLayouts[NEW_HLS_VIEWER_ROLE],
                  ...hlsViewerLayout,
                },
              },
            })
          );
        }
      }
    }
  };
};

export const SET_HLS_RECORDING = "SET_HLS_RECORDING";
export const toggleHLSRecording = (enable: boolean) => {
  return (
    dispatch: AppDispatch,
    getState: () => {
      roles: { policyInfo: policyTemplateType };
    }
  ) => {
    const { policyInfo } = getState().roles;
    const hlsDestinations = policyInfo?.destinations?.hlsDestinations;

    const destinationName = Object.keys(hlsDestinations || {})?.[0];

    // Thumbnail dimensions are not hardcoded - dependent on layers selected
    const topStreamingLayer = hlsDestinations?.[destinationName]
      ?.layers?.[0] || {
      width: 0,
      height: 0,
    };

    const updatedRecordingObject = destinationName
      ? {
          ...defaultRecordingObject,
          thumbnails: {
            ...defaultRecordingObject.thumbnails,
            height: topStreamingLayer?.height,
            width: topStreamingLayer?.width,
          },
        }
      : { ...defaultRecordingObject };

    dispatch({
      type: SET_HLS_RECORDING,
      payload: enable ? updatedRecordingObject : {},
    });
  };
};

export const UPDATE_POLICY_ROLE = "UPDATE_POLICY_ROLE";
export const updatePolicyRole = ({
  roleName,
  path,
  value,
}: {
  roleName: string;
  path: string;
  value: any;
}) => {
  return (dispatch: AppDispatch) => {
    dispatch({
      type: UPDATE_POLICY_ROLE,
      payload: {
        roleName,
        path,
        value,
      },
    });
  };
};

export const UPDATE_TEMPLATE_NAME_INIT = "UPDATE_TEMPLATE_NAME_INIT";
export const UPDATE_TEMPLATE_NAME_DONE = "UPDATE_TEMPLATE_NAME_DONE";
export const UPDATE_TEMPLATE_NAME_FAIL = "UPDATE_TEMPLATE_NAME_FAIL";
export const UPDATE_TEMPLATE_NAME_RESET = "UPDATE_TEMPLATE_NAME_RESET";
export const updateTemplateName: (
  {
    templateName,
    policyId,
  }: {
    templateName: string;
    policyId: string;
  },
  [event, successAnalyticsData, failAnalyticsData]: [
    string | null,
    Record<string, unknown>,
    Record<string, unknown>
  ]
) => (dispatch: any, getState: any) => Promise<void> = (
  { templateName, policyId },
  [event, successAnalyticsData, failAnalyticsData] = [null, {}, {}]
) => {
  return async (dispatch, getState) => {
    const error = (err: Error | AxiosError) => {
      const e = err as AxiosError<DashboardBackendAxiosError> | Error;

      let message = "";
      if (axios.isAxiosError(e)) {
        message = e.response?.data?.msg;
      } else {
        message = e.message;
      }
      toastr.error("Couldn't update template name");
      dispatch({ type: UPDATE_TEMPLATE_NAME_FAIL });
      if (event) {
        AppAnalytics.track(`${event}.failed`, {
          ...failAnalyticsData,
          reasonForFailure: message,
        });
      }
    };
    const { updateTemplateNameStatus } = getState().roles;
    if (updateTemplateNameStatus === API_CALL_STATE.IN_PROGRESS) {
      return;
    }
    dispatch({ type: UPDATE_TEMPLATE_NAME_INIT });
    const resp = await fetchPolicy(policyId)
      .then(async res => {
        if (!res.data.success) {
          throw new Error(res?.data?.msg || res?.data?.message);
        } else {
          return res;
        }
      })
      .catch(err => {
        console.error(err);
        error(err);
      });
    if (resp?.data?.success) {
      resp.data.data.name = templateName;
      updatePolicy({ policy_id: policyId, data: resp.data.data })
        .then(async res => {
          if (res.data.success) {
            dispatch({
              type: UPDATE_TEMPLATE_NAME_DONE,
              payload: { name: templateName, id: policyId },
            });
            if (event) {
              AppAnalytics.track(`${event}.success`, successAnalyticsData);
            }
            toastr.success("Template name updated successfully");
          } else {
            throw Error(res?.data?.msg || res?.data?.message);
          }
        })
        .then(() => {
          dispatch(fetchRecentRooms());
          dispatch(fetchTemplatesData());
        })
        .catch(err => {
          console.error(err);
          error(err);
        });
    } else {
      error(new Error("Policy ID not same"));
    }
  };
};

export const SET_RTMP_DESTINATIONS = "SET_RTMP_DESTINATIONS";
export const toggleRtmp = (flag: boolean) => {
  return (dispatch: AppDispatch) => {
    dispatch({
      type: SET_RTMP_DESTINATIONS,
      payload: flag,
    });
  };
};

export const UPDATE_RTMP_DESTINATIONS = "UPDATE_RTMP_DESTINATIONS";
export const updateRTMPDestinations = (
  payload: rtmpDestinationsType,
  rtmpDestinationsId: string
) => {
  return (
    dispatch: AppDispatch,
    getState: () => { roles: { policyInfo: policyTemplateType } }
  ) => {
    const { policyInfo } = getState().roles;
    if (policyInfo.destinations?.rtmpDestinations) {
      policyInfo.destinations.rtmpDestinations[rtmpDestinationsId] = {
        ...payload,
      };
    }
    dispatch({
      type: UPDATE_RTMP_DESTINATIONS,
      payload: policyInfo,
    });
    //@ts-ignore
    dispatch(validateRTMPDestinations(rtmpDestinationsId));
  };
};

export const VALIDATE_RTMP_DESTINATIONS = "VALIDATE_RTMP_DESTINATIONS";
export const validateRTMPDestinations = (rtmpDestinationsId: string) => {
  return (
    dispatch: AppDispatch,
    getState: () => { roles: { policyInfo: policyTemplateType } }
  ) => {
    const { policyInfo } = getState().roles;
    const { destinations = {} } = policyInfo;
    const { rtmpDestinations = {} } = destinations;
    const invalidFields = validateRTMPdestinationFields(
      rtmpDestinations?.[rtmpDestinationsId] as unknown as rtmpDestinationsType
    );
    dispatch({
      type: VALIDATE_RTMP_DESTINATIONS,
      payload: invalidFields,
    });
  };
};

export const ADD_INTERNAL_RECORDER = "ADD_INTERNAL_RECORDER";

export const addInternalRecorder = () => {
  return (dispatch: AppDispatch) => {
    dispatch({ type: ADD_INTERNAL_RECORDER });
  };
};

export const ADD_SUBDOMAIN_TO_API_TEMPLATE_DONE =
  "ADD_SUBDOMAIN_TO_API_TEMPLATE_DONE";
export const ADD_SUBDOMAIN_TO_API_TEMPLATE_INIT =
  "ADD_SUBDOMAIN_TO_API_TEMPLATE_INIT";
export const ADD_SUBDOMAIN_TO_API_TEMPLATE_FAIL =
  "ADD_SUBDOMAIN_TO_API_TEMPLATE_FAIL";
export function addSubdomainToExistingTemplate(
  policyId: string,
  subdomain: string,
  appType: string
) {
  return async (dispatch: AppDispatch, getState: any) => {
    const { addSubdomainToApiTemplateStatus } =
      getState().roles.addSubdomainToApiTemplateStatus;
    if (addSubdomainToApiTemplateStatus === API_CALL_STATE.IN_PROGRESS) {
      return;
    }
    dispatch({ type: ADD_SUBDOMAIN_TO_API_TEMPLATE_INIT });
    await api
      .service("dashboard")
      .post("/templates/create-subdomain", {
        policy_id: policyId,
        subdomain: subdomain,
        app_type: appType,
      })
      .then(async res => {
        dispatch({
          type: ADD_SUBDOMAIN_TO_API_TEMPLATE_DONE,
          payload: res.data,
        });
        // @ts-ignore
        await dispatch(fetchPolicyInfo(policyId));
        // @ts-ignore
        await dispatch(fetchAppLayout({ templateId: policyId }));
      })
      .catch(err => {
        dispatch({ type: ADD_SUBDOMAIN_TO_API_TEMPLATE_FAIL });
        toastr.error(err.message);
      });
  };
}
