import axios from "axios";
import toastr from "src/components/Common/toastr";
import history from "../helpers/history";
import { isIntegrationFlow } from "../helpers/utils";
import { currentUser } from "../utils";

const services = {
  dashboard: process.env.REACT_APP_BACKEND_API,
  base: process.env.REACT_APP_API_SERVER,
  cms: process.env.REACT_APP_CMS_SERVER,
  auth: process.env.REACT_APP_AUTH_SERVER,
};

export const joinUrl = (baseUrl, url) => {
  return url.indexOf("://") > -1 ? url : baseUrl + url;
};

export const authInterceptor = [
  function (response) {
    return response;
  },
  async function (error) {
    if (error.response?.status === 401) {
      if (!isIntegrationFlow() && currentUser()) {
        toastr.error("Session Expired! Please login again.");
        history.push("/logout");
      }
      await new Promise(() => {});
    }
    return Promise.reject(error);
  },
];

const CancelToken = axios.CancelToken;

export const service = (name, options = {}) => {
  const baseURL = services[name];
  const controller = new AbortController(); // Create an instance of AbortController
  const signal = controller.signal; // Get the signal to pass to axios requests

  const instance = axios.create({
    baseURL,
    headers: { ...getHeaders(name), ...(options?.headers || {}) },
    responseType: options?.responseType ? options.responseType : "*/*",
    signal: signal,
  });

  let methods = {
    get(url, data, options = {}) {
      const paramOption = data ? { params: data } : {};
      return instance.get(url, {
        responseType: "json",
        ...paramOption,
        ...options,
      });
    },
    post(url, data, options = {}) {
      return instance.post(url, data, { responseType: "json", ...options });
    },
    put(url, data, options = {}) {
      return instance.put(url, data, { responseType: "json", ...options });
    },
    patch(url, data, options = {}) {
      return instance.patch(url, data, { responseType: "json", ...options });
    },
    delete(url, data, options = {}) {
      const paramOption = data ? { params: data } : {};
      return instance.delete(url, {
        ...paramOption,
        responseType: "json",
        ...options,
      });
    },
    options(url, data, options = {}) {
      const paramOption = data ? { params: data } : {};
      return instance.options(url, {
        responseType: "json",
        ...paramOption,
        ...options,
      });
    },
    instance() {
      return instance;
    },
    methods() {
      return this;
    },
    cancellable(callback = () => {}) {
      // be careful while cancelling requests for methods other than get
      const source = CancelToken.source();
      instance.interceptors.request.use(config => {
        config.cancelToken = source.token;
        return config;
      });
      callback(source);
      return this;
    },
    cancelRequest() {
      return controller.abort("Request was cancelled by Redux action"); // Cancel the request
    },
    controller() {
      return controller;
    },
    addInterceptors(interceptors = []) {
      interceptors.forEach(interceptor => {
        instance.interceptors.response.use(...interceptor);
      });
    },
    url(url = "") {
      return joinUrl(baseURL, url);
    },
  };

  if (!options.raw) {
    methods.addInterceptors([authInterceptor]);
  }

  return methods;
};

export const getAccessToken = () => {
  const user = currentUser();
  return user?.token;
};

export const getHeaders = serviceName => {
  const headers = {
    "Content-Type": "application/json",
  };
  if (serviceName === "dashboard" || serviceName === "base") {
    const accessToken = getAccessToken();
    if (accessToken) {
      headers["Authorization"] = `Bearer ${accessToken}`;
    }
  }
  return headers;
};

const api = { service };

export default api;
